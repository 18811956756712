@yellow         : #f4e600;
@hero-light-blue: #f0fbff;
@hero-blue      : #bde6f8;
@care-blue      : #f0f9fd;

html {
  overflow-x: hidden;
}

body {
  max-width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  font-family: 'Jost';
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  color: #000;
}

.text-black {
  color: #000;
}

.text-green {
  color: #47550B;
}

.text-mid-green {
  color: #7e9810;
}

.text-light-green {
  color: #9CBC13;
}

.bg-gradient-box-dark {
  .text-green {
    color: #9CBC13;
  }
}

.text-underline {
  text-decoration: underline;
}

.bg-gradient-light {
  background: linear-gradient(#fcf8b3, #e4e98b);
}

.bg-gradient-mid {
  background: linear-gradient(#f8f066, #e6e655);
}

.bg-gradient-box-light {
  background: linear-gradient(#A3C220, #93B019);
}

.bg-gradient-box-dark {
  background: linear-gradient(#596108, #3e4a09);
}

.bg-dark-green {
  background: linear-gradient(194deg, #44c501 37%, #00830d 76%);
}

.bg-yellow {
  background: @yellow;
}

.bg-light-yellow {
  background-color: #f9f6ad;
}

.bg-dark-blue {
  background: linear-gradient(0deg, #00414c 0%, #045a69 100%);
}

.align-center {
  display: flex;
  align-items: center;
}

.btn {
  border: none;
  border-radius: 40px;
  font-size: 17px;
  padding: 12px;
  font-weight: 700;
  line-height: 1.5;
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #919191;
}

.btn-yellow {
  color: #3e4a09;
  background-color: @yellow;
}

.btn-outline-yellow {
  color: #fff;
  border: 1px solid transparent;
  border-color: @yellow;
}

.btn-blue:focus, .btn-blue:hover {
  color: #fff;
  box-shadow: unset;
}

.btn-outline-blue:focus, .btn-outline-blue:hover {
  color: #fff;
  background-color: @yellow;
}

.btn-link:focus, .btn-link:hover {
  text-decoration: none;
  color: #000;
  box-shadow: unset;
}

#quickbar {
  position: fixed;
  z-index: 930;
  top: 280px;
  right: 25px;
  width: 100px;
  background-color: #5c6d13;
  margin-top: -175px;
  border-radius: 0.5rem;

  a {
    text-align: center;
    padding: 15px;
    display: block;
    
    &:first-child {
      padding-top: 30px;
    }

    &:last-child {
      padding-bottom: 30px;
    }
  }
}

#dak-mobile {
  height: 63px;
  font-size: 10px;
  padding-top: 9px;
  background: linear-gradient(0deg, rgba(240,111,63,1) 0%, rgba(244,168,104,1) 100%);

  img {
    border-left: 1px solid #fff;
    margin-top: -3px;
    margin-left: 15px;
    padding-left: 15px;
  }
}

nav {
  background-color: transparent;
  position: absolute !important;
  width: 100%;
  z-index: 999;

  .navbar-brand img {
    width: 100px;
  }

  .nav-link, .nav-link:hover {
    color: #7e9810;
    font-weight: 500;
    text-decoration: underline;
  }

  .nav-item:nth-child(1) {
    border-right: 1ps solid #000;
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

.page_1 nav .nav-item:nth-child(1) .nav-link {
  color: #47550B;
  text-decoration: none;
}
.page_2 nav .nav-item:nth-child(2) .nav-link {
  color: #47550B;
  text-decoration: none !important;
}

.page_3 nav {
  position: relative !important;
}

.page_3 nav .nav-item, .page_3 nav .navbar-toggler {
  visibility: hidden;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.hero {
  background: linear-gradient(#bada31, #7f9910);
  background-repeat: no-repeat;
  background-position-x: right;

  h1 {
    font-size: 37px;
    font-weight: 900;
    line-height: 0.8;
  }

  h1 span {
    font-size: 30px;
    color: #f4e600;
  }

  p {
    color: #d9e3e5;
    line-height: 1.4;
    max-width: 250px;
  }

  &.hero-eltern h1 {
    line-height: 1.1;
  }

  .row-top div {
    margin-top: 4rem;
  }

  .hero-bubble {
    background: linear-gradient(120deg, #596108, #3e4a09 50%);
    padding: 30px;
    max-width: 260px;
    border-radius: 10px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-width: 50px 60px 0 0;
      border-color: #3e4a09 transparent transparent transparent;
      top: 25%;
      right: -59px;
      margin-top: -20px;
    }

    span {
      color: #9CBC13;
    }
  }

  #img-hero-gym {
    margin-top: -170px;
    margin-left: -345px;
  }
  
  #img-hero-quote {
    position: absolute;
    width: 60px;
    top: -45px;
    left: -25px;
  }
  
  #img-hero-team, #img-hero-team-eltern, #img-hero-team-eltern-mobile {
    position: absolute;
    z-index: 910;
    width: 310px;
    top: 160px;
    right: 0px;
  }

  #img-hero-team {
    right: -70px;
    width: 170px;
    top: 220px;
  }

  #img-hero-team-eltern-mobile {
    width: 245px;
    right: -40px;
    top: 272px;
  }

  .btn {
    position: relative;
    z-index: 920;
    font-size: 20px;
    font-weight: normal;
  }

  #btn-contact {
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
  }

  #btn-video {
    color: #fff;
    background-color: #11748a;
    font-size: 18px;
    padding: 15px;

    img {
      margin-top: -5px;
    }
  }

  #modal-hero-video {
    .modal-content {
      background-color: transparent;
      border: 0;
    }

    #modal-btn-close {
      padding: 1rem !important;

      img {
        transform: rotate(45deg);
      }
    }

    iframe {
      width: 100%;
      height: 60vh;
      border: 0;
    }
  }
}

.hero-eltern #img-hero-team-eltern {
  display: none;
}

.usps {
  background: #f4e600;
  padding-top: 30px !important;

  img {
    margin-bottom: -35px;
  }

  .heading-usp {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
  }

  .carousel-item {
    min-height: 410px;
  }

  .carousel-indicators {
    li {
      width: 15px;
      height: 15px;
      margin: 0px 10px;
      border: 3px solid #9CBC13;
      border-radius: 50%;
      background-color: transparent;
      background-clip: unset;
    }

    .active {
      background-color: #293106;
    }
  }
}

.slider-instead-of-usps {
  background: url(/images/bg_insta.png) no-repeat #f4e600;
  padding-top: 30px !important;

  .slider-content {
    position: relative;
  }

  .heading-slider {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
  }

  .carousel-item {
    min-height: 410px;

    img {
      width: 100%;
    }
  }

  .carousel-indicators {
    li {
      width: 15px;
      height: 15px;
      margin: 0px 10px;
      border: 3px solid #9CBC13;
      border-radius: 50%;
      background-color: transparent;
      background-clip: unset;
    }

    .active {
      background-color: #293106;
    }
  }

}

@media (min-width: 992px) {
  .slider-instead-of-usps {

    .slider-content {
      position: absolute;
      left: 30%;
      top: 80px;
      width: 770px;
    }
  }
}

@media (min-width: 1200px) {
  .slider-instead-of-usps {

    .carousel-item {
      img {
        width: 100%;
      }
    }

    .slider-content {
      position: absolute;
      left: 30%;
      top: 120px;
      width: 770px;
    }
  }
}

@media (min-width: 1400px) {
  .slider-instead-of-usps {

    .carousel-item {
      img {
        width: 100%;
      }
    }

    .slider-content {
      position: absolute;
      left: 30%;
      top: 150px;
      width: 770px;
    }
  }
}

@media (min-width: 1600px) {
  .slider-instead-of-usps {

    .carousel-item {
      img {
        width: 75%;
      }
    }

    .slider-content {
      position: absolute;
      left: 680px;
      top: 170px;
      width: 770px;
    }
  }
}

#care {
  #care-overview {
    color: #3e4a09;

    #care-overview-heading {
      font-size: 33px;

      span {
        color: #9cbc13;
      }
    }
  }
}

#contact {
  
  a {
    font-size: 14px;
  }

  a, #contact-intro {
    font-weight: 600;
  }

  img {
    margin-top: -5px;
  }

  .care-icon-alarm {
    margin: 0 auto -40px;
  }

  .bg-gradient-box-dark {
    .col {
      display: flex;
      align-items: flex-start;

      img {
        width: 100%;
      }
    }
  }

}

#faq {
  color: #3e4a09;

  .btn-link {
    color: #3e4a09;
    font-weight: 600;
    border-radius: 0px;
  }

  button[aria-expanded="true"] {
    #icon-faq-plus {
      transform: rotate(45deg);
    }
  }

  .col-faq {
    margin-right: -20px;
  }
}

#modalAppointment {

	.modal-dialog {
		max-width: 100vw;
		margin: 0;
	}

	.modal-content {
		width: 100vw;
		min-height: 100vh;
		border: 0;
		border-radius: 0;

		.container {
			max-width: 600px;
		}

		.modal-header {
			#imgModalAppt {
				background-color: #035563;
				border-radius: 10px;
				padding: 5px;
			}

			#btnCloseModalAppt {
				font-size: 40px;
			}
		}

		#contAppointmentTime {
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
		}

		#appointmentTime {
			border: none;
			border-right: 15px solid transparent;
		}

		.btn-primary, .btn-primary:focus, .btn-primary:active {
			background-color: #209dbc !important;
		}

	}

}

.footer {
  background: linear-gradient(180deg, #3e4a09 37%,#293106 76%);
  font-size: 15px;
}

.footer .h4 {
  font-size: 15px;
  font-weight: 600;
}

.footer hr {
  width: 100%;
  height: 0;
  border: 0;
  border-bottom: 1px solid #3e4a09;
  border-top: 1px solid #3e4a09;
}

a[href="http://www.etracker.de"] {
  display: none;
}

@media (min-width: 400px) {
  .hero h1 {
    font-size: 40px !important;
  }

  .hero h1 span {
    font-size: 30px !important;
    color: #f4e600;
  }

  #img-hero-team {
    width: 195px !important;
    top: 160px !important;
  }

  #contact {
    a {
      font-size: unset !important;
    }
  }
}

@media (min-width: 450px) {
  .hero h1 {
    font-size: 46px !important;
  }

  .hero h1 span {
    font-size: 35px !important;
    color: #f4e600;
  }

  #img-hero-team {
    width: 195px !important;
    right: -30px !important;
  }
}

@media (min-width: 500px) {
  .hero h1 {
    font-size: 52px !important;
  }

  .hero h1 span {
    font-size: 39px !important;
    color: #f4e600;
  }

  .hero p {
    padding-left: 3rem !important;
  }

  #img-hero-quote {
    width: 60px !important;
    top: -35px !important;
    left: 0px !important;
  }

  #img-hero-team {
    width: 210px !important;
  }
}

@media (min-width: 576px) {
  .hero h1 {
    font-size: 57px !important;
  }

  .hero h1 span {
    font-size: 44px !important;
    color: #f4e600;
  }

  .hero .btn {
    font-size: 21px !important;
  }

  #img-hero-team {
    width: 206px !important;
    top: 158px !important;
    right: -30px !important;
  }
}

@media (min-width: 768px) {
  .hero h1 {
    font-size: 77px !important;
  }

  .hero h1 span {
    font-size: 55px !important;
    color: #f4e600;
  }

  .hero .row-top p {
    max-width: 400px !important;
  }

  #img-hero-quote {
    width: 75px !important;
    top: -40px !important;
    left: -20px !important;
  }

  #badge-hero-dak {
    position: absolute;
    width: 161px;
    height: 161px;
    z-index: 900;
    top: 200px;
    right: -10px;
    background: linear-gradient(0deg, rgba(237,107,57,1) 0%, rgba(239,164,101,1) 100%);
    border-radius: 50%;

    #cont-hero-dak {
      position: absolute;
      top: 50%;
      margin-top: -55px;

      #img-hero-dak {
        width: 90px;
        margin: auto;
      }

      #divider-hero-dak {
        width: 50%;
        margin: 7px auto;
        border-top: 1px solid #fff;
      }

      #text-hero-dak {
        width: 75%;
        margin: auto;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        font-size: 11px;
      }
    }
  }

  #img-hero-team {
    width: 156px !important;
    top: 202px !important;
    right: 100px !important;
  }

  #modal-hero-video {
    .modal-dialog {
      width: 75vw;
      max-width: unset;
      margin: 0;

      iframe {
        height: 75vh;
      }
    }
  }

  .usps .carousel-inner {  
    width: 75% !important;
  }
}

@media (max-width: 991px) {
  #care {
    #care-overview-heading {
      font-size: 25px !important;
      line-height: 1.3;
    }
  }

  #contact {
    .h2 {
      font-size: 1.4rem;
    }
  }

  #faq {
    .h2 {
      font-size: 1.7rem;
    }
  }
}

@media (min-width: 992px) {
  nav {
    .container {
      max-width: 1570px;
    }

    .navbar-brand img {
      width: 140px;
    }
  }

  .hero h1 {
    font-size: 85px !important;
  }

  .hero h1 span {
    font-size: 65px !important;
    color: #f4e600;
  }

  .hero .row-top {
    position: relative;
    z-index: 900;

    p {
      max-width: 570px !important;
    }
  }

  .hero .btn {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    font-weight: 500;
  }

  #img-hero-quote {
    width: 83px !important;
    top: -40px !important;
    left: -20px !important;
  }

  #badge-hero-dak {
    width: 179px !important;
    height: 179px !important;
    right: 20px !important;
    top: 170px !important;

    #cont-hero-dak {
      margin-top: -60px !important;

      #img-hero-dak {
        width: 100px !important;
      }

      #text-hero-dak {
        font-size: 12px !important;
      }
    }
  }

  #img-hero-team {
    width: 172px !important;
    top: 162px !important;
    right: 165px !important;
  }

  #info img {
    width: 250px;
    border-radius: 0% !important;
  }

  #contact {
    .bg-gradient-box-dark {
      .col {
        align-items: flex-start;
      }
    }
  }

  .footer hr {
    margin: 35px 0;
  }
}

@media (min-width: 1200px) {

  .hero h1 {
    font-size: 104px !important;
  }

  .hero h1 span {
    font-size: 65px !important;
    color: #f4e600;
  }

  .hero .row-top p {
    font-size: 20px !important;
    max-width: 700px !important;
  }

  .hero .btn {
    font-size: 25px !important;
  }

  #img-hero-quote {
    width: 98px !important;
    left: -38px !important;
  }

  #badge-hero-dak {
    width: 210px !important;
    height: 210px !important;
    top: 155px !important;

    #cont-hero-dak {
      margin-top: -66px !important;

      #img-hero-dak {
        width: 110px !important;
      }

      #text-hero-dak {
        font-size: 14px !important;
      }
    }
  }

  #img-hero-team {
    width: 175px !important;
    top: 170px !important;
    right: 200px !important;
  }

  #modal-hero-video #modal-btn-close img {
    width: 40px !important;
  }
}

@media (min-width: 1400px) {
  #quickbar, #cont-img-hero-gym, #cont-img-hero-quote {
    display: block !important;
  }

  .hero-bubble {
    max-width: 600px;
  }


  .hero h1 {
    font-size: 120px !important;
    max-width: unset !important;
  }

  .hero h1 span {
    font-size: 82px !important;
    color: #f4e600;
  }

  #badge-hero-dak {
    width: 280px !important;
    height: 280px !important;
    right: -30px !important;

    #cont-hero-dak {
      margin-top: -98px !important;

      #img-hero-dak {
        width: 180px !important;
      }

      #text-hero-dak {
        font-size: 18px !important;
      }
    }
  }

  #img-hero-team {
    width: 173px !important;
    top: 210px !important;
    right: 180px !important;
  }
}

#scroll {
  position: absolute;
  bottom: -110px;
  left: 50%; 
  width: 180px;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #9cbc13;

  &:hover {
    text-decoration: none;
  }

  span {
    display: block;
  }
  
  &::before {
    width: 70px;
    height: 130px;
    border-style: solid;
    border-width: 90px 90px 0 90px;
    border-color: #7f9910 transparent transparent transparent;
    content: " ";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }
}

@media (min-width: 768px) {
  #scroll {
    bottom: -110px;
  }
}

@media (min-width: 992px) {
  #scroll {
    bottom: -150px;
  }
}

.eyecatcher {
  position: absolute;
  top: 62px;
  z-index: 0;
  width: 160px;

  .animation {
    animation-name: eyecatcher;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: 20s;
    animation-direction: reverse;
    animation-delay: 0s;
  }

  img {
    width: 160px;
    position: absolute;
  }
}
@media (min-width: 400px) {
  .eyecatcher {
    img {
      width: 160px;
    }
  }
}

@media (min-width: 768px) {
  .eyecatcher {
    top: -10px;

    img {
      width: 360px;
    }
  }
}

small {
  font-size: .775em;
}

.border-top {
  border-top: 1px solid #9cbc13 !important;
}



.cards {
  padding-top: 20px;
  padding-bottom: 20px;

  h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.1;
  }

  h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
      line-height: 1.1;
  }

  p {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 1.4;
  }

  .card {
      border-radius: 5px;
      width: 100%;
      margin-bottom: 50px;
      margin-left: 15px;
      margin-right: 15px;

      span {
          font-weight: 700;
      }

      .card-icon {
        align-self: center;
        margin-bottom: -20px;
      }
  }

  .card-left {
    &:extend(.bg-gradient-box-light);
    color: #fff;
    border: unset;

    h3 {
      color: #3e4a09;
    }
  }

  .card-right {
    &:extend(.bg-gradient-box-dark);
    color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: unset;

    h3 {
      color: #9cbc13;
    }
  }

}

@media (min-width: 1400px) {
  .cards {
      h3 {
          font-size: 32px;
      }

      .card {
          padding: 40px 60px 50px 60px;
          width: 45%;
      }
  }
}

.cards {
  h3 {
      font-size: 32px;
  }

  .card {
      padding: 3rem;
      width: 100%;
  }
}

@media (min-width: 400px) {
  .cards {
      h3 {
          font-size: 32px;
      }

      .card {
          padding: 3rem;
          width: 100%;
      }
  }
}
@media (min-width: 992px) {
  .cards {
      h3 {
          font-size: 32px;
      }

      .card {
          padding: 40px 60px 50px 60px;
          width: 45%;
      }
  }
}

@-webkit-keyframes eyecatcher {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes eyecatcher {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}




@media (min-width: 400px) {
  .hero-eltern h1 {
    font-size: 40px !important;
  }

  .hero-eltern h1 span {
    font-size: 30px !important;
    color: #f4e600;
  }

  #img-hero-team-eltern-mobile {
    width: 245px !important;
    right: -40px !important;
    top: 272px !important;
  }

  #contact {
    a {
      font-size: unset !important;
    }
  }
}

@media (min-width: 450px) {
  .hero-eltern h1 {
    font-size: 46px !important;
    line-height: 1;
  }

  .hero-eltern h1 span {
    font-size: 35px !important;
    color: #f4e600;
  }

  #img-hero-team-eltern-mobile {
    width: 305px !important;
    right: -40px !important;
    top: 207px !important;
  }
}

@media (min-width: 500px) {
  .hero-eltern h1 {
    font-size: 52px !important;
  }

  .hero-eltern h1 span {
    font-size: 39px !important;
    color: #f4e600;
  }

  .hero-eltern p {
    padding-left: 3rem !important;
  }

  #img-hero-quote {
    width: 60px !important;
    top: -35px !important;
    left: 0px !important;
  }

  #img-hero-team-eltern-mobile {
    width: 320px !important;
    top: 225px !important;
  }
}

@media (min-width: 576px) {
  .hero-eltern h1 {
    font-size: 53px !important;
    line-height: 1;
  }

  .hero-eltern h1 span {
    font-size: 44px !important;
    color: #f4e600;
  }

  .hero-eltern .btn {
    font-size: 21px !important;
  }

  #img-hero-team-eltern-mobile {
    width: 355px !important;
    top: 170px !important;
    right: -18px !important;
  }
}

@media (min-width: 768px) {
  .hero-eltern h1 {
    font-size: 67px !important;
    line-height: 1;
  }

  .hero-eltern h1 span {
    font-size: 55px !important;
    color: #f4e600;
  }

  .hero-eltern .row-top p {
    max-width: 400px !important;
  }

  #img-hero-quote {
    width: 75px !important;
    top: -40px !important;
    left: -20px !important;
  }

  #badge-hero-dak {
    position: absolute;
    width: 161px;
    height: 161px;
    z-index: 900;
    top: 170px;
    right: -20px;
    background: linear-gradient(0deg, rgba(237,107,57,1) 0%, rgba(239,164,101,1) 100%);
    border-radius: 50%;

    #cont-hero-dak {
      position: absolute;
      top: 50%;
      margin-top: -55px;

      #img-hero-dak {
        width: 90px;
        margin: auto;
      }

      #divider-hero-dak {
        width: 50%;
        margin: 7px auto;
        border-top: 1px solid #fff;
      }

      #text-hero-dak {
        width: 75%;
        margin: auto;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        font-size: 11px;
      }
    }
  }

  #img-hero-team-eltern {
    width: 336px !important;
    top: 272px !important;
    right: -30px !important;
  }
}

@media (max-width: 991px) {
  #care {
    #care-overview-heading {
      font-size: 25px !important;
      line-height: 1.3;
    }
  }

  #contact {
    .h2 {
      font-size: 1.4rem;
    }
  }

  #faq {
    .h2 {
      font-size: 1.7rem;
    }

    .col-faq {
      margin-right: -10px;
    }
  }
}

@media (min-width: 992px) {

  .hero-eltern h1 {
    font-size: 85px !important;
    line-height: 1;
  }

  .hero-eltern h1 span {
    font-size: 65px !important;
    color: #f4e600;
  }

  .hero-eltern .row-top {
    position: relative;
    z-index: 900;

    p {
      max-width: 570px !important;
    }
  }

  .hero-eltern .btn {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    font-weight: 500;
  }

  #img-hero-quote {
    width: 83px !important;
    top: -40px !important;
    left: -20px !important;
  }

  #badge-hero-dak {
    width: 179px !important;
    height: 179px !important;
    right: -10px !important;
    top: 150px !important;

    #cont-hero-dak {
      margin-top: -60px !important;

      #img-hero-dak {
        width: 100px !important;
      }

      #text-hero-dak {
        font-size: 12px !important;
      }
    }
  }

  #img-hero-team-eltern {
    width: 435px !important;
    top: 272px !important;
    right: 5px !important;
  }

  #info img {
    width: 250px;
    border-radius: 0% !important;
  }

  #contact {
    .bg-gradient-box-dark {
      .col {
        align-items: flex-start;
      }
    }
  }

  .footer hr {
    margin: 35px 0;
  }
}

@media (min-width: 1200px) {
  .hero-eltern h1 {
    font-size: 88px !important;
    line-height: 1;
  }

  .hero-eltern h1 span {
    font-size: 65px !important;
    color: #f4e600;
  }

  .hero-eltern .row-top p {
    font-size: 20px !important;
    max-width: 700px !important;
  }

  .hero-eltern .btn {
    font-size: 25px !important;
  }

  #img-hero-quote {
    width: 98px !important;
    left: -38px !important;
  }

  #badge-hero-dak {
    width: 210px !important;
    height: 210px !important;
    top: 285px !important;
    right: -20px !important;

    #cont-hero-dak {
      margin-top: -66px !important;

      #img-hero-dak {
        width: 110px !important;
      }

      #text-hero-dak {
        font-size: 14px !important;
      }
    }
  }

  #img-hero-team-eltern {
    width: 455px !important;
    top: 248px !important;
    right: 50px !important;
  }
}

@media (min-width: 1400px) {

  .hero-eltern .hero-bubble {
    max-width: 600px;
  }


  .hero-eltern h1 {
    font-size: 100px !important;
    max-width: unset !important;
    line-height: 1.1;
  }

  .hero-eltern h1 span {
    font-size: 82px !important;
    color: #f4e600;
  }

  .hero-eltern #badge-hero-dak {
    width: 280px !important;
    height: 280px !important;
    right: -100px !important;
    top: 245px !important;

    #cont-hero-dak {
      margin-top: -98px !important;

      #img-hero-dak {
        width: 180px !important;
      }

      #text-hero-dak {
        font-size: 18px !important;
      }
    }
  }

  #img-hero-team-eltern {
    width: 455px !important;
    top: 312px !important;
    right: 40px !important;
  }
}

#bob-menu-toggler {
  visibility: hidden;
  height: 0;
  position: absolute;
}

#bob-menu-toggler:checked~.navbar #navbarToggler01 {
  display: block !important;
  background: linear-gradient(#f8f066, #e6e655);
  width: 100%;
  position: absolute;
  height: 100vh;
  top: -63px;
  left: 0;
  padding: 20px 20px 0 25%;
  z-index: 9999;
}

#bob-menu-toggler:checked~.navbar #navbarToggler01 .align-items-end {
  align-items: flex-start !important;
}

#bob-menu-toggler:checked~.navbar #navbarToggler01 .close-button {
  display: block !important;
  font-size: 40px;
  padding-right: 20px;
  cursor: pointer;
}

#bob-menu-toggler:checked~.navbar #navbarToggler01 .menu-label-head {
  display: block !important;
}

.nav-mobile-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.menu-label-head {
  height: 100px;
  line-height: 100px;
  font-size: 24px;
  font-weight: 600;
  margin-top: 30px;
  text-transform: uppercase;
}

.animated-icon1 {
  cursor: pointer;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  width: 40px;
}

.animated-icon1 span, .animated-icon2 span, .animated-icon3 span {
  border-radius: 9px;
  display: block;
  height: 3px;
  left: 0;
  opacity: 1;
  position: absolute;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  width: 100%;
  background-color: #fff;
}

.animated-icon1 span:first-child {
  top: 0;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.border-radius-top {
  border-radius: 5px 5px 0 0;
}

.border-radius-bottom {
  border-radius: 0 0 5px 5px;
}